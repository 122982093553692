// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-submit-js": () => import("./../../../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-templates-cycle-template-js": () => import("./../../../src/templates/cycleTemplate.js" /* webpackChunkName: "component---src-templates-cycle-template-js" */),
  "component---src-templates-scoreboard-template-js": () => import("./../../../src/templates/ScoreboardTemplate.js" /* webpackChunkName: "component---src-templates-scoreboard-template-js" */)
}

